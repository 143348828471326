<script>
  export default {
    data() {
      return {};
    },
    created() {

    },
    methods:{

    },
    watch: {

    }
  };
</script>

<template>
  <div class="container">
    <header>222</header>
    <section>22</section>
    <main>1</main>
    <footer>33</footer>
  </div>
</template>

<style lang="less" scoped>
  .container {
    width: 100%;
    height: 100%;
    background-color: red;
  }
</style>
